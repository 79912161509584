const GoogleAnalyticsPage = gql`
  fragment GTM on Page {
    metaGoogleAnalytics {
      category
      fieldGroupName
      name
      segment
      type
    }
  }
`;

export {
  GoogleAnalyticsPage
};
